import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from 'src/environments/environment';
import {
  AppStatus,
  getFileName,
  LabInfo,
  LabInputTypeOption,
  SkideoCreateService,
} from '@vasio-nl/skideo-create-lib';
import {map, Subject, Subscription, throttleTime} from 'rxjs';
import fileDownload from 'js-file-download';
import {FormControl, FormGroup} from '@angular/forms';

export enum ContentType {
  IMAGE = 'image',
  VIDEO = 'video',
  GIF = 'gif',
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  appStatus: AppStatus = AppStatus.WAITING;
  contentType: ContentType | null = null;
  videoUrl: string | null = null;
  imageUrl: string | null = null;
  posterUrl: string | null = null;
  AppStatus = AppStatus;
  ContentType = ContentType;
  downloading = false;
  download$ = new Subject<Event>();
  labInfo: LabInfo | null = null;
  createForm: FormGroup | null = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private createService: SkideoCreateService,
  ) {}

  ngOnInit(): void {
    this.contentType = this.route.snapshot.params['type'];
    const lookupId = this.route.snapshot.params['lookup_id'];
    const labInfo$ = this.createService.getLabInfo$(lookupId, environment.instantHost);

    this.subscriptions.push(
      labInfo$.subscribe(labInfo => (this.labInfo = labInfo)),

      labInfo$
        .pipe(map(labInfo => this.buildInitialForm(labInfo)))
        .subscribe(createForm => (this.createForm = createForm)),

      this.download$.pipe(throttleTime(500)).subscribe(event => this.download(event)),
    );

    if (this.contentType === ContentType.VIDEO) {
      this.videoUrl = `${environment.instantHost}sites/create/${this.route.snapshot.params['lookup_id']}/${this.route.snapshot.params['hash']}`;
      if (this.route.snapshot.params['poster_hash']) {
        this.posterUrl = `${environment.instantHost}sites/create/${this.route.snapshot.params['poster_lookup_id']}/${this.route.snapshot.params['poster_hash']}`;
      }
    } else {
      this.imageUrl = `${environment.instantHost}sites/create/${this.route.snapshot.params['lookup_id']}/${this.route.snapshot.params['hash']}`;
    }
  }

  clickDownload(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.download$.next(event);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private download(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.downloading = true;
    let downloadSrc: string | null = null;
    switch (this.contentType) {
      case ContentType.IMAGE:
        downloadSrc = this.imageUrl ?? null;
        break;
      case ContentType.VIDEO:
      case ContentType.GIF:
        downloadSrc = this.videoUrl ?? null;
        break;
    }

    if (downloadSrc === null) {
      return;
    }

    fetch(downloadSrc, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: 'cors',
    })
      .then(response => response.blob())
      .then(blob => {
        const fileName = getFileName(this.labInfo, this.createForm, downloadSrc);
        if (!fileName) {
          return;
        }
        fileDownload(blob, fileName);
        this.downloading = false;
      })
      .catch((e: Error) => {
        this.createService.error$.next({
          error: e,
          message: $localize`An error occurred preparing your download.`,
        });
        this.downloading = false;
      });
  }

  private buildInitialForm(labInfo: LabInfo): FormGroup {
    this.createForm = new FormGroup({});
    for (const input of labInfo.inputs) {
      const override = labInfo.overrides.find(o => o.name === input.name);
      switch (input.type) {
        case LabInputTypeOption.TEXT:
        case LabInputTypeOption.TEXTAREA: {
          const fieldValue = override?.default_value ?? input.default_value ?? '';
          const control = new FormControl(fieldValue);
          if (override?.locked) {
            control.disable();
          }
          this.createForm.addControl(input.name, control);
          break;
        }
        case LabInputTypeOption.DROPDOWN: {
          const fieldValue = override?.default_value ?? input.data_source.options[0].value;
          const control = new FormControl(fieldValue);
          if (override?.locked) {
            control.disable();
          }
          this.createForm.addControl(input.name, control);
          break;
        }
        case LabInputTypeOption.LAB_SELECTION:
          this.createForm.addControl(input.name, new FormControl(''));
          break;
        case LabInputTypeOption.VIDEO_UPLOAD:
        case LabInputTypeOption.IMAGE_UPLOAD: {
          const fieldValue = override?.default_value ?? '';
          const control = new FormControl(fieldValue);
          if (override?.locked) {
            control.disable();
          }
          this.createForm.addControl(input.name, control);
          break;
        }
        default:
          break;
      }
    }
    return this.createForm;
  }
}
