import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  ngOnInit(): void {
    if (environment.production) {
      window.location.href = 'https://skideo.com';
    } else {
      window.location.href = '/skideoai';
    }
  }
}
