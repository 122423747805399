import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {NgxSkideoCreateModule, SkideoCreateService} from '@vasio-nl/skideo-create-lib';
import {AppComponent} from './app.component';
import {LabComponent} from './lab/lab.component';
import {LottieModule} from 'ngx-lottie';
import {LottiePlayer} from 'lottie-web';
import player from 'lottie-web';
import {RedirectComponent} from './redirect/redirect.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {LandingComponent} from './landing/landing.component';
import {PasswordDialogComponent} from './lab/password-dialog/password-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import * as Sentry from '@sentry/angular-ivy';

// Required by AOT compiler, see docs: https://www.npmjs.com/package/ngx-lottie#usage
export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LabComponent,
    RedirectComponent,
    LandingComponent,
    PasswordDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSkideoCreateModule,
    AppRoutingModule,
    LottieModule.forRoot({player: playerFactory}),
    MatSnackBarModule,
    MatDialogModule,
    ReactiveFormsModule,
  ],
  providers: [
    SkideoCreateService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
