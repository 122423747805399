import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LabComponent} from './lab/lab.component';
import {LandingComponent} from './landing/landing.component';
import {RedirectComponent} from './redirect/redirect.component';

const routes: Routes = [
  {path: ':type/:lookup_id/:hash', pathMatch: 'full', component: LandingComponent},
  {
    path: ':type/:lookup_id/:hash/:poster_lookup_id/:poster_hash',
    pathMatch: 'full',
    component: LandingComponent,
  },
  {path: ':id', component: LabComponent},
  {path: '', component: RedirectComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
