import {Component, Inject, OnInit, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

export type DialogData = {password: string};
export type DialogResult = {isPasswordCorrect: boolean};

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class PasswordDialogComponent implements OnInit, OnDestroy {
  errorState = false;
  input = new FormControl();
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<PasswordDialogComponent, DialogResult>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(this.input.valueChanges.subscribe(() => (this.errorState = false)));
  }

  submit(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.input.value === this.data.password) {
      this.dialogRef.close({isPasswordCorrect: true});
    } else {
      this.input.reset();
      this.errorState = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
