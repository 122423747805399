<main class="dialog-wrapper">
  <h2 mat-dialog-title i18n>Password protection</h2>
  <section mat-dialog-content>
    <p i18n>This template is password-protected. Please enter the password to unlock it.</p>
    <form (submit)="submit($event)">
      <input
        matInput
        type="password"
        class="pw-input"
        [class.error]="errorState"
        [formControl]="input"
        (click)="errorState = false"
        (focus)="errorState = false" />
    </form>
    <div class="error-messages">
      <p class="incorrect" [class.hidden]="!errorState">Incorrect password.</p>
    </div>
  </section>
  <section class="button-wrapper" mat-dialog-actions>
    <button mat-flat-button i18n (click)="submit($event)">Submit</button>
  </section>
</main>
