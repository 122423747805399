import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  Sentry.init({
    dsn: 'https://20784c5f1c99e77125eb8f33746b4388@o4507882170548224.ingest.de.sentry.io/4507882916413520',
    integrations: [],
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
