<article class="page-container" *ngIf="labInfo">
  <section class="gallery-wrapper">
    <lib-gallery *ngIf="labInfo && generatedContent" [fixedGallery]="true" [labInfo]="labInfo" [generatedContent]="generatedContent">
    </lib-gallery>
  </section>

  <div class="form-wrapper">
    <lib-form
      *ngIf="labInfo"
      [portal]="false"
      [instantHost]="environment.instantHost"
      [urlHash]="urlHash"
      [labInfo]="labInfo"
      (generatedContent$)="generatedContent$.next($event)">
    </lib-form>
  </div>
</article>
