<div class="background">
  <header class="landing-header">
    <div class="header-wrapper">
      <img src="assets/imgs/logo-skideo-text.png" alt="logo" />
      <h4>Data driven creative content</h4>
    </div>
  </header>

  <main class="error" *ngIf="appStatus === AppStatus.ERROR">
    <h3 i18n>Your media cannot be displayed.</h3>
  </main>

  <main class="content" *ngIf="appStatus !== AppStatus.ERROR">
    <section class="video-wrapper" *ngIf="videoUrl">
      <video
        #videoPlayer
        controls
        *ngIf="videoUrl"
        playsinline
        [poster]="posterUrl ? posterUrl : null"
        (canplaythrough)="appStatus = AppStatus.WAITING"
        i18n>
        <source [src]="videoUrl + '#t=0.01'" type="video/mp4" (error)="appStatus = AppStatus.ERROR" />
        Sorry, your browser doesn't support embedded videos.
      </video>
    </section>

    <section class="image-wrapper" *ngIf="imageUrl">
      <img id="img" [src]="imageUrl" />
    </section>

    <section class="button-wrapper">
      <button class="button-image-download" (click)="clickDownload($event)">
        <img src="assets/imgs/download-dark.svg" alt="download" />
        <span *ngIf="downloading" i18n>Downloading...</span>
        <span *ngIf="!downloading" i18n>Download content</span>
      </button>
    </section>
  </main>
</div>
